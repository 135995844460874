import Image from "next/image";
import React from "react";
import PasswordChecker from "../modules/auth/PasswordChecker";

interface BaseInputProps {
  defaultErrors?: string[];
  label?: string;
  id?: string;
  className?: string;
  prefixIcon?: any;
  max?: boolean;
  handleMaxClick?: (event: any) => void;
  [key: string]: any;
}

export default function BaseInput({
  defaultErrors,
  label,
  id,
  className = "",
  prefixIcon,
  register,
  errors,
  type,
  children,
  max,
  handleMaxClick,
  ...nativeInputProps
}: BaseInputProps) {
  const [inputType, setInputType] = React.useState(type || "text");

  return (
    <label className={`base-input ${className}`}>
      <div
        className={`base-input__text-space-wrapper ${
          prefixIcon ? "has-prefix-icon" : ""
        }`}
      >
        {!!prefixIcon && <span className="base-input__icon">{prefixIcon}</span>}
        <input
          id={id}
          className="base-input__text-space"
          type={inputType}
          {...(register && id && register(id))}
          {...nativeInputProps}
        />
        {type && type === "password" && (
          <span
            className="base-input__icon-right pointer"
            onClick={() => {
              setInputType((prev: any) =>
                prev === "password" ? "text" : "password"
              );
            }}
          >
            <Image
              src={`/assets/images/${
                inputType === "password" ? "password-eye" : "eye"
              }.svg`}
              height={20}
              width={20}
              alt="eye"
            />
          </span>
        )}
        {children}
      </div>

      <div className="base-input__label flex-row-between items-center">
        {label ? <span>{label}</span> : null}
        <span>
          {max ? (
            <button
              className="base-btn--link"
              type="button"
              onClick={handleMaxClick}
            >
              Max
            </button>
          ) : null}
        </span>
      </div>
      {defaultErrors?.map?.((error: string, index: number) => (
        <span className="base-input__error" key={index}>
          {error}
        </span>
      ))}
      {id
        ? id !== "password" &&
          errors && (
            <span className="base-input__error"> {errors[id]?.message}</span>
          )
        : null}
    </label>
  );
}
