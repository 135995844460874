import { formData } from "../model";
import domtoimage from "dom-to-image";

export const formatApiErrors = (errors: string[]) => {
  const groupedErrors: any = {};
  errors.forEach((error: any) => {
    error = {
      key: error.field,
      value: error.message
    };

    if (groupedErrors[error.key]) groupedErrors[error.key].push(error.value);
    else groupedErrors[error.key] = [error.value];
  });

  return groupedErrors;
};

export const generateInputChangeHandler = (setFormData: formData) => {
  return ({ currentTarget: { name, value } }: any) => {
    setFormData((data: any) => ({ ...data, [name]: value }));
  };
};

export const getFirstLetter = (name: string) => {
  if (!name) return "";
  return name?.charAt?.(0)?.toUpperCase?.();
};

export const getInitials = (name: string) => {
  if (!name) return "";
  const names = name.split(" ");
  let initials = names[0].charAt(0).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].charAt(0).toUpperCase();
  }
  return initials;
};

export const handleFormInputChange = (setFormData: formData) =>
  generateInputChangeHandler(setFormData);

export const downloadImage = (id: string, name: string) => {
  const node = document.getElementById(id);
  if (!node) {
    return;
  }
  domtoimage.toBlob(node).then(function (blob) {
    var link = document.createElement("a");
    link.download = name;
    link.href = URL.createObjectURL(blob);
    link.click();
  });
};

export const getTotal = (array: any, key: string) => {
  if (!array) return 0;
  const total = array.reduce((acc: any, item: any) => {
    return acc + Number(item[key]);
  }, 0);
  return total;
};

export const getFilteredData = (array: any, key: string, value: string) => {
  if (!array) return 0;
  const filtered = array.filter((item: any) => item[key] === value);
  return filtered;
};

export const roundUp = (num: number, precision: number) => {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};

export const roundToDecimalPlaces = (number: number, precision: number) => {
  const factor = 10 ** precision;
  return (Math.floor(number * factor) / factor).toFixed(precision);
};

export const isStringArray = (value: any): value is string[] => {
  return Array.isArray(value) && value.every((v) => typeof v === "string");
};

export const getDropdownClass = (
  index: number,
  totalRows: number,
  rowsWithBottom: number
) => {
  return index >= totalRows - rowsWithBottom ? "bottom" : "top";
};
